<template>
  <div class="order_refund" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="退换/售后"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <!-- 商品信息 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="init"
      v-if="list.length > 0"
    >
      <div v-for="(li, i) in list" :key="i">
        <div class="order_info">
          <div class="order_title">
            <span>{{
              li.RefundType == 0
                ? "仅退款"
                : li.RefundType == 1
                ? "退款退货"
                : li.RefundType == 2
                ? "换货"
                : ""
            }}</span>
            <span :class="li.RefundStatus == 0 ? 'c_F95E2E' : 'c_B3B3B4'">{{
              li.RefundStatus == 0
                ? "进行中"
                : li.RefundStatus == 1
                ? "已完成"
                : li.RefundStatus == 2
                ? "申请失败"
                : ""
            }}</span>
          </div>
          <div class="info_detail">
            <img :src="li.ImgUrl" alt="" />
            <div class="detail_content">
              <div class="decimalPoint-2">{{ li.GoodsName }}</div>
              <div>
                <span>{{ li.SpecName }}</span>
              </div>
              <div>
                <span>￥{{ li.PayMoney }}</span
                ><span>×{{ li.RefundGoodsCount }}</span>
              </div>
            </div>
          </div>
          <div class="info_line"></div>
          <div class="info_btn">
            <div @click="handleCancel(li)" v-if="li.RefundStatus == 0">
              取消售后
            </div>
            <div @click="goto(li)">查看详情</div>
          </div>
        </div>
      </div>
    </van-list>
    <div v-else>
      <nocontent name="暂无退换货列表"></nocontent>
    </div>
  </div>
</template>

<script>
import { queryApplyRefundList, deleteShopRefund } from "@/api/shop";
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    nocontent,
  },
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      userInfo: {},
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "退换/售后";
      this.isWxApplets = false;
    }
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // console.log(this.userInfo, "");
  },
  mounted() {
    this.init();
  },
  methods: {
    // 取消
    async handleCancel(obj) {
      const res = await deleteShopRefund(obj.Id);
      if (res.status == 200) {
        this.$message.success(res.msg);
        this.list = [];
        this.page = 1;
        this.loading = false;
        this.finished = false;
        this.init();
      } else {
        this.$message.error(res.msg);
      }
    },
    goto(obj) {
      this.$router.push({
        path: "/shop/orders/refundDetail",
        query: { id: obj.Id },
      });
    },
    async init() {
      let parm = {
        userId: this.userInfo.Id,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      console.log(parm);
      const res = await queryApplyRefundList(parm);
      this.list = this.list.concat(res.response.data);
      this.page++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.list.length >= res.response.dataCount) {
        this.finished = true;
      }
    },
    onClickLeft() {
      window.history.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-ellipsis {
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
}
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}
//导航栏下边框
/deep/ .van-hairline--bottom::after {
  border: 0;
}
.order_refund {
  padding: 15px 15px 0;
  min-height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  flex-direction: column;
  .order_info {
    background: #fff;
    width: 100%;
    // height: 12.6rem;
    box-sizing: border-box;
    padding: 15px;
    position: relative;
    border-radius: 0.375rem;
    margin-bottom: 15px;

    .order_title {
      font-size: 0.875rem;
      color: #333333;
      padding-bottom: 0.9375rem;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      span:last-child {
        font-weight: 400;
        color: #f95e2e;
      }
    }
    .info_detail {
      display: flex;
      img {
        width: 5.5625rem;
        height: 5.5rem;
        flex: 0 0 5.5625rem;
        border-radius: 0.25rem;
        margin-right: 0.9375rem;
      }
      .detail_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div:first-child {
          font-size: 0.875rem;
          color: #333333;
          font-weight: 700;
        }
        div:nth-child(2) {
          font-size: 0.75rem;
          color: #858585;
          display: flex;
          justify-content: space-between;
        }
        div:nth-child(3) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.75rem;
          color: #fe4a4a;
          span {
            color: #fe4a4a;
            font-size: 0.9375rem;
            font-weight: 700;
          }
          span:nth-child(2) {
            font-size: 0.75rem;
            color: #858585;
            font-weight: 400;
          }
        }
      }
    }
    .info_line {
      margin-top: 1.125rem;
      width: 100%;
      height: 1px;
      background: #efefef;
    }
    .info_btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 0.9375rem;
      div {
        width: 5rem;
        height: 1.875rem;
        text-align: center;
        line-height: 1.875rem;
        font-size: 0.75rem;
        color: #333333;
        border-radius: 1.25rem;
        border: 1px solid #e9e7e7;
      }
      div:last-child {
        color: #f95e2e;
        border: 1px solid #f95e2e;
        margin-left: 0.625rem;
      }
    }
  }
}
.c_F95E2E {
  color: #f95e2e;
}
.c_B3B3B4 {
  color: #b3b3b4;
}
</style>
